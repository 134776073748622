<template>
  <div class="main">
    <div class="dashboard-card ticket-detail-card">
      <div class="ticket-detail-header row">
        <div class="info-container col-lg-9 col-sm-12">
          <b-row class="ticket-header-row">
            <b-col cols="6" xl="3" class="info mb-2">
              <h4>Ticket ID</h4>
              <span class="text">{{ticket.ticketNo}}</span>
            </b-col>
            <b-col cols="6" xl="3" class="info mb-2">
              <h4>Date</h4>
              <span class="text">{{ticket.createdDateLabel}}</span>
            </b-col>
            <b-col cols="6" xl="3" class="info mb-2">
              <h4>Status</h4>
              <div>
                <span v-if="ticket.isClosed">
                  <span class="lab-badge lab-danger">Closed</span>
                </span>
                <span v-else>
                  <span v-show="ticket.ticketStatus === 0 && ticket.isRead === false" class="lab-badge lab-pale-marigold">Pending</span>
                  <span v-show="ticket.ticketStatus === 1" class="lab-badge lab-badge-mellow-melon">New Message</span>
                </span>
              </div>
            </b-col>
            <b-col cols="6" xl="3" class="info mb-2">
              <h4>Service</h4>
              <div>
                <span v-show="ticket.ticketType === 0" class="lab-badge lab-badge-lago-blue">G25</span>
                <span v-show="ticket.ticketType === 1" class="lab-badge lab-badge-lago-blue">Trait And Health</span>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="close-container col-lg-3 col-sm-12" v-if="!ticket.isClosed">
          <button class="lab-btn lab-btn-outline-mellow" @click="closeTicket">Close Ticket</button>
        </div>
      </div>

      <hr class="header-hr"/>
      <div class="ticket-detail-message cs-scroll-style" v-if="ticket">
        <div v-for="(item, index) in ticketDetails" :key="item.id">
          <div class="message-content">
            <h5 class="mb-0 user" :class="{ 'lab-text-success': item.customerName === null }">
              {{ item.customerName === null ? item.userName : item.customerName}}
            </h5>
            <div class="message" v-html="item.message">
            </div>
            <a v-if="item.fileName" class="p-1" :href="item.fileName" target="_blank">Open Uploaded File</a>
          </div>

          <hr v-show="index !== ticketDetails.length - 1" />
        </div>
      </div>

      <div class="send-message-container" v-if="!ticket.isClosed">
        <div>
          <quill-editor
            v-model="messageData.message"
            :options="snowOption"
          />
        </div>
        <div>
          <div class="mt-2">
            <div class="file-upload btn-file-upload" v-if="messageData.file === null"
                @click="openUpload()">
              <feather-icon
                icon="UploadIcon"
                size="20"
                class="text-primary"
              />
              Upload File
            </div>
            <div class="file-upload" v-else>
              <feather-icon 
                icon="Trash2Icon" size="18"
                @click="removeUploadFile()"
                class="text-danger btn-file-change-delete"/>
              {{ this.messageData.file.name }}
              <feather-icon
                icon="CheckIcon"
                size="20"
                class="text-success"
              />
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end mt-2">
          <button class="lab-btn lab-btn-info btn-save" 
            type="button" @click="createMessage">
              <b-spinner
              v-if="$store.getters.getLoader"
              class="mr-1"
              small
              variant="light"
            />
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'

// Alert
import AlertService from '@/common/alert.service'
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], ['link']],
        },
      },
      messageData: {
        message: null,
        file: null,
        ticketId: null,
      },
    }
  },
  methods: {
    fetchTicketDetails() {
      const paramId = this.$router.currentRoute.params.id;
      this.$store.dispatch('ticket/fetchTicket', { ticketNo: paramId });
    },

    createMessage() {
      if (this.messageData.message === null) {
        AlertService.warning(this, 'Write a message');
        return;
      }

      const formData = new FormData();
      formData.set('ticketId', this.ticket.id);
      formData.set('message', this.messageData.message);
      formData.set('file', this.messageData.file);

      this.$store.dispatch('ticket/createTicketDetail', formData)
        .then(response => {
          if (response.statusCode === 200) {
            this.messageData.message = null;
            this.messageData.file = null;

            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
            this.fetchTicketDetails();
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
    openUpload() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        /* eslint-disable*/
        const file = event.target.files[0];
        const fileTypes = StaticOptions.ticketFileTypeOptions;
        if (fileTypes.indexOf(file.type) > -1) {
          this.messageData.file = file;
        } else {
          AlertService.warning(this, 'Invalid file...! Accepted File Formats:(.jpg,.jpeg,.png)');
        }
      })
      fileInput.click();
    },
    removeUploadFile() {
      this.messageData.file = null;
    },

    closeTicket() {
      this.$store.dispatch('ticket/closeTicket', { ticketId: this.ticket.id })
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
            this.fetchTicketDetails();
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
  },
  computed: {
    ticket() {
      const ticket = this.$store.getters['ticket/getTicketData'];
      if (ticket === null) {
        return null;
      }

      return ticket.customerTicket;
    },

    ticketDetails() {
      const ticket = this.$store.getters['ticket/getTicketData'];
      if (ticket === null) {
        return null;
      }
      
      ticket.customerTicketDetails.forEach(item => {
        item.message = item.message ? item.message.replace('\n', '<br>') : '';
      });

      return ticket.customerTicketDetails;
    },
  },
  mounted() {
    this.fetchTicketDetails();
  },
}
</script>

<style lang="scss" scoped>
.file-upload {
  font-size: 15px;
  padding: 0.5rem;
}
.btn-file-upload {
  width: 130px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #00adb53b;
  transition: all 0.05s linear;
}

.btn-file-upload:hover {
  transform: rotate(0deg) scale(1.03, 1.03);
}

.btn-file-change-delete {
  cursor: pointer;
  border-radius: 5px;
}

.btn-file-change-delete:hover {
  background-color: #ea54554f !important;
}

.main {
  margin: 0 auto;
  max-width: 1000px !important;
}

.ticket-detail-header {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding: 1.5rem 1.5rem 0.4rem 1.5rem;
  .info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      h4 {
        font-weight: bold;
        font-size: 1rem;
        color: var(--lab-carbon-fiber);
      }
      text {
        font-weight: 500;
        font-size: 0.9rem;
        color: var(--lab-stieglitz-silver);
      }
      span {
        font-size: 0.8rem;
      }
    }
  }
  .close-container {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .ticket-header-row {
    width: 100% !important;
  }
}

.header-hr {
  border: 1px dashed;
}

.ticket-detail-message {
  // max-height: 200px;
  // overflow: auto!important;
  // overflow-x: hidden!important;
  padding: 1.5rem 2rem;

  .message-content {
    h5 {
      color: var(--lab-carbon-fiber);
      font-size: 1rem;
    }
    .message {
      padding: 1rem;
      color: var(--lab-carbon-fiber);
    }
  }
}

.send-message-container {
  padding: 1.5rem;
  
  .btn-save {
    padding: 0.5rem 1.2rem !important;
    font-size: 1rem;
  }
}

.dark-layout .ticket-detail-header .info-container .info h4 {
  color: var(--lab-lago-blue-color);
}
.dark-layout .ticket-detail-header .info-container .info span {
  color: var(--lab-doctor);
}
.dark-layout .ticket-detail-message .message-content h5 {
  color: var(--lab-lago-blue-color);
}
.dark-layout .ticket-detail-message .message-content .message {
  color: var(--lab-doctor);
}
</style>
